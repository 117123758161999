import React from 'react';
import Container from 'react-bootstrap/Container';
import ReactLoading from 'react-loading';

class LoadingScreen extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      className: 'loading-screen',
    };
  }

  componentDidMount() {
    setTimeout(function() { var lt = document.getElementById('loading-text'); if (lt) lt.innerHTML = "LOADING - HANG TIGHT" }, 10000);
  }

  hide = () => {

    this.setState({className: 'loading-screen dissolve'});

    setTimeout(() => {
      this.setState({className: 'loading-screen invisible'})
    }, 1000);

  }


  render() {
    const { className } = this.state;

    return (
        <div className={className}>
            <Container fluid>
              <div className="h-100 d-flex flex-column justify-content-center align-items-center z10">

                <div id="loading-text"> LOADING </div>
                <ReactLoading id="loading-bar" type="bars" color="#000" height="32px" margin-left='0' />

              </div>
            </Container>
        </div>
    )
  }
}

export default LoadingScreen;
