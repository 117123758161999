import { Water } from 'three/examples/jsm/objects/Water';
import { Sky } from 'three/examples/jsm/objects/Sky';

const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

AFRAME.registerComponent( 'sea_shader', {

	schema: {},

	init() {

		this.setupWater();
		this.setupSky();
		this.setupGui();

	},

	setupWater: function () {

		const scene = this.el.sceneEl.object3D;

		const waterGeometry = new THREE.PlaneGeometry( 10000, 10000 );

		this.water = new Water(
			waterGeometry,
			{
				textureWidth: 512,
				textureHeight: 512,
				waterNormals: new THREE.TextureLoader().load( '/textures/waternormals.jpg', function ( texture ) {

					texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

				} ),
				alpha: 1.0,
				sunDirection: new THREE.Vector3(),
				sunColor: 0xffffff,
				waterColor: 0x0c3b66,
				distortionScale: 0.5,
				fog: scene.fog !== undefined
			}
		);

		this.water.material.uniforms.size.value = 10;

		this.water.rotation.x = - Math.PI / 2;

		scene.add( this.water );

	},

	setupSky: function () {

		const scene = this.el.sceneEl.object3D;

		this.sun = new THREE.Vector3();

		this.sky = new Sky();
		this.sky.scale.setScalar( 10000 );
		scene.add( this.sky );

		const skyUniforms = this.sky.material.uniforms;
		skyUniforms[ 'turbidity' ].value = 10;
		skyUniforms[ 'rayleigh' ].value = 2;
		skyUniforms[ 'mieCoefficient' ].value = 0.005;
		skyUniforms[ 'mieDirectionalG' ].value = 0.8;

		this.parameters = {
			inclination: 0.49,
			azimuth: 0.25

			// __ sunset
			// inclination: 0.4951,
			// azimuth: 0.2338

			// __ night time with moonlight
			// inclination: 0.4469,
			// azimuth: 0.8

			// __ big bright white sun, blue sky with sunset horizon
			// inclination: 0.49,
			// azimuth: 0.25
		};

		this.pmremGenerator = new THREE.PMREMGenerator( this.el.sceneEl.renderer );

		this.updateSun();

	},

	updateSun: function () {

		const { sun, sky, water, pmremGenerator, parameters, el } = this;
		const scene = el.sceneEl.object3D;

		const theta = Math.PI * ( parameters.inclination - 0.5 );
		const phi = 2 * Math.PI * ( parameters.azimuth - 0.5 );

		sun.x = Math.cos( phi );
		sun.y = Math.sin( phi ) * Math.sin( theta );
		sun.z = Math.sin( phi ) * Math.cos( theta );

		sky.material.uniforms[ 'sunPosition' ].value.copy( sun );
		water.material.uniforms[ 'sunDirection' ].value.copy( sun ).normalize();

		scene.environment = pmremGenerator.fromScene( sky ).texture;

	},

	setupGui: function () {

		if ( ! window.Gui ) return;

		const gui = window.Gui;
		const { parameters } = this;

		const folderSky = gui.addFolder( 'Sky' );
		folderSky.add( parameters, 'inclination', 0, 0.5, 0.0001 ).onChange( this.updateSun.bind( this ) );
		folderSky.add( parameters, 'azimuth', 0, 1, 0.0001 ).onChange( this.updateSun.bind( this ) );
		folderSky.open();

		const waterUniforms = this.water.material.uniforms;

		const folderWater = gui.addFolder( 'Water' );
		folderWater.add( waterUniforms.distortionScale, 'value', 0, 8, 0.1 ).name( 'distortionScale' );
		folderWater.add( waterUniforms.size, 'value', 0.1, 10, 0.1 ).name( 'size' );
		folderWater.add( waterUniforms.alpha, 'value', 0.9, 1, .001 ).name( 'alpha' );
		folderWater.open();

	},

	tick: function ( time, tDelta ) {

		this.water.material.uniforms[ 'time' ].value += 1.0 / 60.0;

	}
} );
