import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UIButtons from './UIButtons';
import HandleMobilePortraitOrientation from './HandleMobilePortraitOrientation';


// controls any UI that appears on top of the scene
class HUD extends React.Component {

  constructor(props) {
	super(props);

	this.state = {}

  }


  render() {

	return (

		<div id="hud">

			<HandleMobilePortraitOrientation />

			<UIButtons />

			<ToastContainer
				className='toast-container'
				position="top-left"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnVisibilityChange
				draggable
				pauseOnHover
			/>
		</div>

	)
  }
}

export default HUD;
