
const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

// checks the URL for a localhost or test URL to add the stats component to the scene
AFRAME.registerSystem( 'smart_stats', {

	init() {

		if (
			(
				window.location.host === 'localhost:3001' ||
				window.location.host.includes( '.ngrok.io' ) ||
				window.location.host.includes( '.netlify' )
			)
			&& window.location.href.includes( 'debug' )
		) {

			this.el.setAttribute( 'stats', '' ); // add stats component to scene el

		}

	}
} );
