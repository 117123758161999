import * as dat from 'dat.gui';

const AFRAME = window.AFRAME;

AFRAME.registerSystem( 'gui_debugger', {

	init: function () {

		if (
			( window.location.host === 'localhost:3001' || window.location.host.includes( '.ngrok.io' ) ) &&
			window.location.href.includes( 'debug' )
		) {

			window.debuggui = true;

		}

		if ( window.debuggui && ! this.el.sceneEl.isMobile ) {

			window.Gui = new dat.GUI( { width: 420 } );

		}

	}
} );
