const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

AFRAME.registerComponent( 'nft_fetcher', {

	schema: {},

	init() {

		document.addEventListener( 'SearchUIInjected', () => {

			this.defineReferencesToInputElements();
			this.checkForAddressInURL();

		} );

	},

	// enables address to be input in URL and have that addresses NFT's appear automatically
	// ex. https://nft.workroomvr.com/0x6dfdaf8dg8da9d88f7gdhs8ddsduid
	checkForAddressInURL: function () {

		if ( window.location.pathname.length > 1 ) {

			let addressToFetch = window.location.pathname.slice( 1 ).trim(); // remove '/' and any whitespace

			this.input_address_el.value = addressToFetch;

			setTimeout( () => {

				this.fetchTokensFromOpenSea();

			}, 1000 );

		}

	},

	defineReferencesToInputElements: function ( event ) {

		this.input_address_el = document.getElementById( 'nft_address_input' );
		this.submitBtn = document.getElementById( 'nft_fetcher_submit' );

		this.submitBtn.addEventListener( 'click', this.handleAddressInput.bind( this ) );

	},

	handleAddressInput: function ( event ) {

		event.preventDefault();

		this.fetchTokensFromOpenSea();

	},

	fetchTokensFromOpenSea: async function () {

		const address_to_fetch = this.input_address_el.value.trim();
		if ( ! address_to_fetch ) {

			alert( 'Input ETH address' );
			return;

		}

		console.log( `address_to_fetch`, address_to_fetch );

		let url = 'https://api.opensea.io/api/v1/assets?';
		const params = {
			owner: address_to_fetch,
			order_by: 'visitor_count',
			order_direction: 'desc' // asc = oldest first
		};
		url = url + new URLSearchParams( params );

		const response = await fetch( url );

		if ( response.status >= 400 && response.status < 600 ) {

			alert( "Error fetching ETH address. Please try with a different address." );
			return;

		}

		const json = await response.json();

		if ( json.assets.length === 0 ) {

			alert( "Address owns no NFT's" );
			return;

		}

		// add address for easy access
		json.address = address_to_fetch;

		document.dispatchEvent( new CustomEvent( 'LoadNewAddressNFTs', { detail: json } ) );

	}


} );
