const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

AFRAME.registerComponent( 'nft_ui_controller', {

	schema: {},

	init() {

		this.indexCount = 5;

		setTimeout( () => {

			this.nft_controller_component = document.getElementById( 'nft_controller' ).components[ 'nft_controller' ];
			this.nftTransformArray = this.nft_controller_component.nftTransformArray;

			this.nft_ui_0 = document.getElementById( 'nft_ui_0' );
			this.nft_ui_1 = document.getElementById( 'nft_ui_1' );
			this.nft_ui_2 = document.getElementById( 'nft_ui_2' );
			this.nft_ui_3 = document.getElementById( 'nft_ui_3' );
			this.nft_ui_4 = document.getElementById( 'nft_ui_4' );

			this.nft_btn_0 = document.getElementById( 'nft_btn_0' );
			this.nft_btn_1 = document.getElementById( 'nft_btn_1' );
			this.nft_btn_2 = document.getElementById( 'nft_btn_2' );
			this.nft_btn_3 = document.getElementById( 'nft_btn_3' );
			this.nft_btn_4 = document.getElementById( 'nft_btn_4' );

			this.nft_text_0 = document.getElementById( 'nft_text_0' );
			this.nft_text_1 = document.getElementById( 'nft_text_1' );
			this.nft_text_2 = document.getElementById( 'nft_text_2' );
			this.nft_text_3 = document.getElementById( 'nft_text_3' );
			this.nft_text_4 = document.getElementById( 'nft_text_4' );

			this.nft_btn_0.addEventListener( 'click', this.handleSeeMoreClick.bind( this ) );
			this.nft_btn_1.addEventListener( 'click', this.handleSeeMoreClick.bind( this ) );
			this.nft_btn_2.addEventListener( 'click', this.handleSeeMoreClick.bind( this ) );
			this.nft_btn_3.addEventListener( 'click', this.handleSeeMoreClick.bind( this ) );
			this.nft_btn_4.addEventListener( 'click', this.handleSeeMoreClick.bind( this ) );

		}, 1000 );


		document.addEventListener( 'CameraRotationComplete', this.handleCameraRotationComplete.bind( this ) );

	},

	handleCameraRotationComplete: function ( event ) {

		// __ set all ui elements position.y to 5 to prepare for animation
		for ( var i = 0; i < this.indexCount; i ++ ) {

			this[ `nft_ui_${i}` ].object3D.position.y = 5;

		}

		// trigger position animation on UI
		let art_index_with_focus = event.detail.activeIndex - 1;
		if ( art_index_with_focus >= 0 )
			this[ `nft_ui_${art_index_with_focus}` ].emit( 'triggerAppearAnimation' );


	},

	handleSeeMoreClick: function ( event ) {

		let nft_index = event.target.id.slice( - 1 );

		let nft_data = this.nft_controller_component.nftDataStore[ parseInt( nft_index ) ];

		document.dispatchEvent( new CustomEvent( 'NFTSelected', { detail: nft_data } ) );

	},

	updateUiElements: function ( nftDataStore ) {

		let iterations = Math.min( nftDataStore.length, this.indexCount );

		let text_el, ui_el;

		for ( let i = 0; i < iterations; i ++ ) {

			const nftData = nftDataStore[ i ];

			ui_el = this[ `nft_ui_${i}` ];
			text_el = this[ `nft_text_${i}` ];

			// TODO: position the elements correctly depending on art's dimensions
			// set position of UI elements
			let posArr = this.nftTransformArray[ i ].position.split( " " );
			posArr = posArr.map( Number );
			posArr[ 1 ] = 5;
			ui_el.object3D.position.fromArray( posArr );
			ui_el.setAttribute( 'rotation', this.nftTransformArray[ i ].rotation );

			// update text to NFT's name
			text_el.setAttribute( 'text', `value: ${nftData.name}` );

		}

	},

} );
