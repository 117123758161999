const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

const TWEEN = require( '@tweenjs/tween.js' );

AFRAME.registerComponent( 'camera_rotation_controls', {

	schema: {},

	init() {

		this.rotationFactor = 1;
		this.activeIndex = 0; // start facing the screen, aka index 0
		this.rotationObj = {
			current: Math.PI / 180 * - ( 360 + 0 ), // starting rotation
		};
		this.rotationOptions = [
			0,
			90,
			135,
			180,
			225,
			270,
		];
		this.tweenDuration_ms = 750;
		this.isTweenRunning = false;

		// init camera rotation
		this.camera = this.el.getObject3D( 'camera' );
		this.camera.rotation.y = this.rotationObj.current;
		this.camera.updateProjectionMatrix();

		document.addEventListener( 'NFTSelected', this.rotateCameraToScreen.bind( this ) );
		document.addEventListener( 'MoveToNextNFT', this.moveCameraToNFT.bind( this, true ) );
		document.addEventListener( 'MoveToPrevNFT', this.moveCameraToNFT.bind( this, false ) );

	},

	moveCameraToNFT: function ( isNext, event ) {

		// make sure animation isn't already happening
		if ( this.isTweenRunning ) return;

		this.isTweenRunning = true;

		// alert that we're about to rotate away from screen
		if ( this.activeIndex === 0 )
			document.dispatchEvent( new CustomEvent( 'BeforeRotationAwayFromScreen' ) );

		// rotate clockwise
		if ( isNext ) {

			this.iterateTargetIndex( true );


		} else { // rotate counterclockwise

			this.iterateTargetIndex( false );

		}

		this.rotateCamera();

	},

	rotateCamera: function () {

		let targetRotation = this.getTargetRotation();

		// TODO: don't make a new TWEEN
		this.activeTween = new TWEEN.Tween( this.rotationObj )
			.to( { current: targetRotation }, this.tweenDuration_ms )
			.easing( TWEEN.Easing.Quadratic.Out )
			.onUpdate( () => {

				this.camera.rotation.y = this.rotationObj.current;
				this.camera.updateProjectionMatrix();

			} )
			.onComplete( () => {

				this.isTweenRunning = false;

				document.dispatchEvent( new CustomEvent( 'CameraRotationComplete', { detail: { activeIndex: this.activeIndex } } ) );

			} );

		this.activeTween.start();

	},


	rotateCameraToScreen: function ( evt ) {

		// make sure animation isn't already happening
		if ( this.isTweenRunning ) return;

		this.isTweenRunning = true;

		// if camera is rotated into the 2nd half of the circle
		if ( this.activeIndex > this.rotationOptions.length / 2 )
			this.rotationFactor += 1;

		// set active index to the screen's index
		this.activeIndex = 0;

		this.rotateCamera();

	},

	/**
	 *
	 * HELPERS
	 *
	 */

	getTargetRotation: function () {

		let rotationTarget_deg = this.rotationOptions[ this.activeIndex ];

		return Math.PI / 180 * - ( ( 360 * this.rotationFactor ) + rotationTarget_deg );

	},

	iterateTargetIndex: function ( shouldIterateForward ) {

		if ( shouldIterateForward ) {

			if ( this.activeIndex === this.rotationOptions.length - 1 ) {

				this.activeIndex = 0;
				this.rotationFactor += 1;

			} else {

				this.activeIndex += 1;

			}

		} else { // should iterate backwards

			if ( this.activeIndex === 0 ) {

				this.activeIndex = this.rotationOptions.length - 1;
				this.rotationFactor -= 1;

			} else {

				this.activeIndex -= 1;

			}

		}

	},


	/**
	 *
	 * render loop
	 *
	 */

	tick: function () {

		if ( this.isTweenRunning )
			TWEEN.update();

	}



} );
