import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

AFRAME.registerComponent( 'css3d_screen_controller', {

	schema: {},

	init() {

		this.isFirstSearch = true;
		this.screenPosVec = new THREE.Vector3( 0, 3.1, - 11 );

		// get ref to camera
		this.cameraEl = document.getElementById( "main-camera" );
		if ( this.cameraEl.getObject3D( 'camera' ) ) {

			this.camera = this.cameraEl.getObject3D( 'camera' );

		} else {

			this.cameraEl.addEventListener( 'object3dset', () => {

				this.camera = this.cameraEl.getObject3D( 'camera' );

			} );

		}


		window.addEventListener( 'resize', this.onWindowResize.bind( this ) );
		document.addEventListener( 'LoadNewAddressNFTs', this.handleNewAddressSearch.bind( this ) );
		document.addEventListener( 'NFTSelected', this.handleNFTSelected.bind( this ) );
		document.addEventListener( 'CameraRotationComplete', this.makeIframeVisibleIfFocused.bind( this ) );
		document.addEventListener( 'BeforeRotationAwayFromScreen', this.toggleIframeVisibility.bind( this, false ) );


		this.initRendererAndScreen();


	},

	initRendererAndScreen: function () {


		// __ css3D renderer __
		this.rendererCss = new CSS3DRenderer();
		this.rendererCss.setSize( window.innerWidth, window.innerHeight );
		this.rendererCss.domElement.style.position = 'absolute';
		this.rendererCss.domElement.style.top = 0;

		// __ webGL renderer - make transparent __
		this.el.sceneEl.renderer.setClearColor( 0x00ff00, 0.0 );

		// __ webGL and css3d canvas - stack z-index correctly __
		this.rendererCss.domElement.prepend( this.el.sceneEl.canvas ); // move webGL canvas inside css3d container
		document.body.appendChild( this.rendererCss.domElement );
		this.rendererCss.domElement.lastChild.id = 'css3D_screen_el';
		this.rendererCss.domElement.lastChild.classList.add( 'cssCanvas' ); // give it proper z-index

		// __ css3D scene __
		this.sceneCss = new THREE.Scene();
		this.sceneCss.scale.set( 0.01, 0.01, 0.01 ); // required to make everything align

		// __ webGL (3D) screen mesh __
		var geometry = new THREE.PlaneGeometry( 12, 6 );
		// var material = new THREE.MeshBasicMaterial( { color: 0x1d38cd, opacity: 1.0 } ); // FOR NIGHTTIME LIGHTING
		var material = new THREE.MeshBasicMaterial( { color: 0x05173e, opacity: 1.0 } );
		this.screenMesh = new THREE.Mesh( geometry, material );
		this.screenMesh.position.copy( this.screenPosVec );
		this.el.sceneEl.object3D.add( this.screenMesh );

		this.injectScreenContents();


	},

	injectScreenContents: function () {

		let el, css3dObject;

		el = document.getElementById( 'css3d_screen' ).firstChild;
		css3dObject = new CSS3DObject( el );
		css3dObject.position.copy( this.screenMesh.position ).multiplyScalar( 100 );
		css3dObject.rotation.copy( this.screenMesh.rotation );
		this.sceneCss.add( css3dObject );

		el.parentElement.removeChild( el );

		setTimeout( () => {

			this.iframe_el = css3dObject.element.children[ 0 ];
			this.introUI_el = css3dObject.element.children[ 1 ];
			this.browserBar_el = css3dObject.element.children[ 2 ];

			document.dispatchEvent( new CustomEvent( 'SearchUIInjected', {
				detail: {
					iframe_el: this.iframe_el,
					introUI_el: this.introUI_el,
					browserBar_el: this.browserBar_el,
				}
			} ) );


			// fix firefox bug where css3D contents don't show up
			if ( navigator.userAgent.includes( 'Firefox' ) ) {

				this.rendererCss.setSize( window.innerWidth + 1, window.innerHeight + 1 );
				alert("Use the Chrome or Safari browser for full functionality")

			}

		}, 1000 );

	},

	presentIntroUI: function () {

		this.introUI_el.classList.add( 'zOnTop' );
		this.iframe_el.classList.remove( 'zOnTop' );

	},

	presentIframe: function () {

		this.browserBar_el.classList.add( 'lighter-box-shadow' );
		this.iframe_el.classList.add( 'zOnTop' );
		this.toggleIframeVisibility( true );

		this.introUI_el.parentElement.removeChild( this.introUI_el );

		this.screenMesh.material.color.set( 0xffffff );

		this.isFirstSearch = false;

	},

	handleNewAddressSearch: function ( event ) {

		let eth_address = event.detail.address;
		if ( ! eth_address ) return;

		this.iframe_el.setAttribute( 'src', `https://opensea.io/accounts/${eth_address}?search[sortAscending]=false&search[sortBy]=VIEWER_COUNT` );

		if ( this.isFirstSearch )
			this.presentIframe();

	},

	// show the selected NFT's detail page on opensea
	handleNFTSelected: function ( event ) {

		const { contract_address, token_id } = event.detail;

		// TODO: make sure this is an iframe
		this.iframe_el.setAttribute( 'src', `https://opensea.io/assets/${contract_address}/${token_id}` );

	},

	makeIframeVisibleIfFocused: function ( event ) {

		if ( event.detail.activeIndex === 0 ) {

			this.toggleIframeVisibility( true );

		}

	},

	toggleIframeVisibility: function ( toVisible ) {

		if ( toVisible )
			this.iframe_el.classList.remove( 'invisible' );
		else
			this.iframe_el.classList.add( 'invisible' );

	},

	onWindowResize: function () {

		this.camera.aspect = window.innerWidth / window.innerHeight;
		this.camera.updateProjectionMatrix();

		this.rendererCss.setSize( window.innerWidth, window.innerHeight );

	},

	tick() {

		if ( this.rendererCss && this.camera )
			this.rendererCss.render( this.sceneCss, this.camera );

	},

} );
