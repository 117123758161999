/* Generates and returns html from a json schema to create an AFrame scene
 * Recurses through an object schema, (see below) to generate html
 * @param {Object} element to generate
 * @param {!Object} parent element that it will append to
 */
const recurseChildren = ( el ) => {

	let html = `<${el.type}`;
	// loop through key values and set attributes
	// type, children, and innerHTML are ignored
	Object.keys( el ).forEach( key => {

		if (
			key !== 'type' &&
			key !== 'children' &&
			key !== 'innerHTML' &&
			key !== 'inputType'
		) {

			let value = el[ key ];
			html = `${html} ${key}="${value}"`;

		}

	} );

	// enabling input type since we use 'type' as the html tag
	if ( el.inputType ) {

		html = `${html} type="${el.inputType}"`;

	}

	html = `${html}>`;

	if ( el.innerHTML ) {

		html = `${html}${el.innerHTML}`;

	}


	// recurse through the children if they exist
	// and render each one
	if ( el.children ) {

		el.children.forEach( ( child ) => {

			let childHtml = recurseChildren( child );
			html = `${html}${childHtml}`;

		} );

	}

	return `${html}</${el.type}>`;

};

const aframeFromJson = ( el ) => {

	let html = recurseChildren( el );
	let div = document.createElement( "div" );
	div.innerHTML = html;
	return div.children[ 0 ];

};

export default aframeFromJson;
