import React from 'react';
import { Modal } from 'react-bootstrap';

class HandleMobilePortraitOrientation extends React.Component {

  constructor(props) {
	super(props);

	this.state = {
		isVisible: false
	}

  }

  componentDidMount() {

	setTimeout(() => {

		this.shouldModalBeVisibile();

	}, 500);

	window.addEventListener('orientationchange', this.shouldModalBeVisibile.bind(this));

  }

  shouldModalBeVisibile = () => {

	setTimeout(() => {

		if (window.innerHeight > window.innerWidth && window.AFRAME.scenes[0].isMobile) {

			this.setState({isVisible: true})

		} else {

			this.setState({isVisible: false})

		}

	}, 500);

  }


  render() {

	const { isVisible } = this.state;

	return (

		<Modal id="mobilePortraitModal" show={isVisible}>
			<Modal.Body>
				Rotate your phone sideways
			</Modal.Body>
		</Modal>

	)
  }
}

export default HandleMobilePortraitOrientation;
