
import '../../materials/ComposedTexture';

const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

/**
 * IDEAS:
 *
 * browser bar in bottom of css3D screen that shows current address,
 * enables input of new address,
 * a way to navigate back to accounts page,
 * and fullscreen mode that sends the css3D screen fullscreen
 *
 * NFT's UI could be a small css3D iframe's presenting the opensea ui for that NFT on the addresses account page
 *
 * Each rotation around the circle, load new NFT's
 *
 * Have a mode where the camera auto-rotates and give the user some ability to pause it
 *
 * Move the art and iframe screen be farther off out in the ocean then when the user gets to it, have it fly in close to the user
 *
 * cursor make the water ripple and wake around it
 */


// (((((((((((((((((((((((((((((())))))))))))))))))))))))))))))

// ~~~~~~~~ TODO before sending as a showcase/demo ~~~~~~~~~~~

// ######## MUST have #########



// ######## NICE to have #########

// better looking loading screen

// mobile? I think it's okay to wait for that and make clear in the pitch we can make mobile work. Show them braille on mobile if needed for credibility

// each rotation around circle, load new NFT's

// design loading indicator in browser bar search button

// NFT UI position and rotation should be set programmatically depending on size

// NO iframe scroll in firefox https://github.com/inuyaksa/jquery.nicescroll/issues/322

// check animation_url to see if NFT is a video, if so, display that


// fix issue with nft_fetcher.js not finding form elements in DOM

// properly dispose of nft media textures

// switch to use asset's permalink to determine NFT's detail page URL on opensea


// ((((((((((((((((((((((()))))))))))))))))))))))

AFRAME.registerComponent( 'nft_controller', {

	schema: {},

	init() {

		this.nftTransformArray = [
			{
				"position": "5 1 0",
				"rotation": "0 -90 0"
			},
			{
				"position": "3.5 1 3.5",
				"rotation": "0 -135 0"
			},
			{
				"position": "0 1 5",
				"rotation": "0 180 0"
			},
			{
				"position": "-3.5 1 3.5",
				"rotation": "0 135 0"
			},
			{
				"position": "-5 1 0",
				"rotation": "0 90 0"
			},
		];
		this.transformIndex = 0;

		this.nftDataStore = [];

		this.nftContainer = document.getElementById( 'nft_container' );

		this.fileLoader = this.el.sceneEl.systems[ 'asset_helper' ].fileLoader;
		this.imageLoader = this.el.sceneEl.systems[ 'asset_helper' ].imageLoader;

		setTimeout( () => {

			this.nft_ui_controller_component = document.getElementById( 'nft_container' ).components[ 'nft_ui_controller' ];

		}, 1000 );

		document.addEventListener( 'LoadNewAddressNFTs', this.LoadNewNFTs.bind( this ) );

	},

	LoadNewNFTs: function ( event ) {

		this.clearAssetsAndEntities();

		if ( event.detail && event.detail.assets ) {

			this.compileRelevantNFTData( event.detail.assets );

		} else {

			console.log( 'ERROR in nft_controller.js. No NFT assets found.' );

		}

	},

	clearAssetsAndEntities: function () {

		// return if first load event
		if ( ! this.nftContainer || ! this.nftContainer.children.length ) return;

		// clear data store
		this.nftDataStore = [];

		// __ clear art entities __
		let parent = this.nftContainer;
		// while ( parent.firstChild ) {

		// 	let el = parent.lastChild;
		// 	parent.removeChild( el );
		// 	el.destroy();
		// 	// TODO: delete the entity and dispose of it's geometry + material + texture

		// }

		// __ clear a-assets __
		parent = this.el.sceneEl.firstChild;
		while ( parent.firstChild ) parent.lastChild.remove();

	},

	compileRelevantNFTData: async function ( nftArray ) {

		for ( let i = 0; i < nftArray.length; i ++ ) {

			const nftObject = nftArray[ i ];

			const mediaType = await this.fetchMediaType( nftObject.image_url );

			let nftData = {
				name: nftObject.name,
				description: nftObject.description,
				media_src: nftObject.image_url,
				media_type: mediaType,
				contract_address: nftObject.asset_contract.address,
				token_id: nftObject.token_id
			};

			this.nftDataStore.push( nftData );

		}

		// console.log( this.nftDataStore );

		this.maxIterations = Math.min( nftArray.length, this.nftTransformArray.length );

		this.fetchMediaAndCreateArt();
		this.nft_ui_controller_component.updateUiElements( this.nftDataStore, this.maxIterations );

	},

	fetchMediaAndCreateArt: async function () {

		const { nftDataStore } = this;

		for ( let i = 0; i < this.maxIterations; i ++ ) {

			const nftData = nftDataStore[ i ];

			if ( nftData.media_type.includes( 'gif' ) ) {

				// GIF

				this.GIFLoader( nftData.media_src, async ( container ) => {


					let dimensionsObj = this.calcProperDimensions( container.width, container.height );

					let gifGeometry = new THREE.PlaneBufferGeometry( dimensionsObj.width, dimensionsObj.height );
					let gifMaterial = new THREE.MeshBasicMaterial( {
						map: new THREE.ComposedTexture( container )
					} );

					let mesh = new THREE.Mesh( gifGeometry, gifMaterial );

					let artEl = document.getElementById( `nft_art_${i}` );
					artEl.setAttribute( 'position', this.nftTransformArray[ i ].position );
					artEl.setAttribute( 'rotation', this.nftTransformArray[ i ].rotation );

					artEl.setObject3D( 'mesh', mesh );

				} );


			} else if (
				nftData.media_type.includes( 'png' ) ||
				nftData.media_type.includes( 'jpeg' ) ||
				nftData.media_type.includes( 'svg' )
			) {

				// IMAGE

				this.imageLoader.load(

					nftData.media_src,

					// onLoad
					( image ) => {

						const img_id = `nft_media_${i}`;
						const art_id = `nft_art_${i}`;

						// inject image into a-assets
						image.id = img_id;
						this.el.sceneEl.children[ 0 ].appendChild( image );

						// configure a-entity for this image
						let artEl = document.getElementById( art_id );
						artEl.setAttribute( 'position', this.nftTransformArray[ i ].position );
						artEl.setAttribute( 'rotation', this.nftTransformArray[ i ].rotation );
						artEl.setAttribute( 'material', `shader: flat; src: #${img_id};` );
						let dimensionsObj = this.calcProperDimensions( image.width, image.height );
						artEl.setAttribute( 'geometry', `primitive: plane; width: ${dimensionsObj.width}; height: ${dimensionsObj.height}` );


					}
				);


			} else {

				console.warn( "Not Image data in nft_controller" );

			}

		}

	},


	tick: function ( t, dt ) {

		THREE.ComposedTexture.update( dt * 0.001 );

	},


	/**
	 *
	 * HELPERS
	 */


	GIFLoader: function ( url, complete ) {


		this.fileLoader.responseType = 'arraybuffer';
		this.fileLoader.load( url, async function ( data ) {

			const gif = new window.GIF( data );

			const container = {
				downscale: false,	// canvas needs to be power of 2, by default size is upscaled (false)
				width: gif.raw.lsd.width,
				height: gif.raw.lsd.height,
				frames: gif.decompressFrames( true )
			};

			complete( container );

		} );


	},

	fetchMediaType: async function ( src ) {

		let mediaType = undefined;

		// fetching headers for media type
		const res = await fetch( src, { method: 'HEAD' } );

		for ( var pair of res.headers.entries() ) {

			if ( pair[ 0 ] === 'content-type' )
				mediaType = pair[ 1 ];

		}

		return mediaType;

	},

	calcProperDimensions: function ( width, height ) {

		const aspectRatio = height / width; // defining aspect ratio

		var height_meters;
		var width_meters;

		 // handle square(ish) art
		if ( aspectRatio >= .8 && aspectRatio <= 1.2 ) {

		  height_meters = 1.7;
		  width_meters = height_meters / aspectRatio;
		  width_meters = Number( width_meters.toFixed( 3 ) ); // round to 3 decimals

		} else if ( aspectRatio < .8 ) { // handle wide art

		  width_meters = 2;
		  height_meters = width_meters * aspectRatio;
		  height_meters = Number( height_meters.toFixed( 3 ) ); // round to 3 decimals

		} else if ( aspectRatio > 1.2 ) { // handle tall art

		  height_meters = 2;
		  width_meters = height_meters / aspectRatio;
		  width_meters = Number( width_meters.toFixed( 3 ) ); // round to 3 decimals

		}

		return {
		  height: height_meters,
		  width: width_meters
		};

	}



} );
