import React from 'react';
import ReactDOM from 'react-dom';
import HUD from './HUD';
import LoadingScreen from './LoadingScreen';

// controls whether the loading screen or HUD is active
class ReactRoot extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showLoadingScreen: true,
    }

    this.loadingScreen = React.createRef();
  }

  componentDidMount() {


    document.addEventListener('RevealScene', () => {

      this.setState({ showLoadingScreen: false });
			this.loadingScreen.current.hide();

    });

  }


  render() {

    const { showLoadingScreen } = this.state;

    return (
      <div id="ReactRoot" className="ReactRoot">

        {/* becomes hidden via animation when scene is ready */}
        <LoadingScreen ref={this.loadingScreen} />

        { !showLoadingScreen && <HUD/> }

      </div>
    )
  }
}

ReactDOM.render(<ReactRoot />, document.getElementById('react-root'));
