const init = async () => {

	require( './react/Root' );
	require( './a-frame/init' );
	require( './styles/styles.scss' );

};

init();

