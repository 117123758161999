// packages

// controllers
import "./components/css3d_screen_controller";
import "./components/nft_ui_controller";
import "./components/camera/touch-look-controls";
import "./components/camera/zoom_controls";
import "./components/camera/camera_rotation_controls";
import "./components/blockchain/nft_controller";
import "./components/blockchain/nft_fetcher";

// physics

// assets in the scene
import "./components/sea_shader";

// functions / helpers
import "./systems/asset_helper";
import "./systems/smart_stats";
import "./systems/gui_debugger";
import "./components/log_gltf";
import './polyfills';
import aframeFromJson from './modules/aframe-from-json';



// const AFRAME = window.AFRAME;


//____ init aframe scene ____
function aframeInit() {


	// convert scene's json to html and inject into DOM
	var sceneJson = require( './json/a-scene.json' );
	var sceneHtml = aframeFromJson( sceneJson );
	document.body.appendChild( sceneHtml );

	// wait for scene to load
	var aScene = document.getElementById( 'scene' );
	aScene.addEventListener( 'loaded', () => {

		setTimeout( () => { // just for testing purposes

			// signal to react UI that scene is ready to go
			document.dispatchEvent( new CustomEvent( 'RevealScene' ) );

		}, 1000 );

	} );


}

aframeInit();

