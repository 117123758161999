import { BasisTextureLoader } from 'three/examples/jsm/loaders/BasisTextureLoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';

const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

// helper system for loading models and textures
AFRAME.registerSystem( 'asset_helper', {

	init() {

		this.setupAssetLoaders();

	},

	// setup asset loaders for other components to use
	setupAssetLoaders: function () {

		// generic fileLoader
		this.fileLoader = new THREE.FileLoader();

		// image loader
		this.imageLoader = new THREE.ImageLoader();

		// texture loader
		this.textureLoader = new THREE.TextureLoader();

		// gltf loader
		this.GLTFLoader = new THREE.GLTFLoader();

		// draco loader
		var dracoLoader = new THREE.DRACOLoader();
		dracoLoader.setDecoderPath( '/draco/' );
		this.GLTFLoader.setDRACOLoader( dracoLoader );

		// ktx2 loader
		var ktx2Loader = new KTX2Loader();
		ktx2Loader.detectSupport( this.el.sceneEl.renderer );
		this.GLTFLoader.setKTX2Loader( ktx2Loader );

		// basis texture loader
		this.basisLoader = new BasisTextureLoader();
		this.basisLoader.setTranscoderPath( '/basis/' );
		this.basisLoader.detectSupport( this.el.sceneEl.renderer );

	},

} );
