import React from 'react';

class UIButtons extends React.Component {

	constructor(props) {
		super(props);

		this.state = {}

		document.addEventListener('LoadNewAddressNFTs', this.makeUiVisible);

	}

	makeUiVisible() {

		document.getElementById('next-nft-btn').classList.remove('invisible');
		document.getElementById('prev-nft-btn').classList.remove('invisible');

	}


	handleNextClick() {

		document.dispatchEvent(new CustomEvent('MoveToNextNFT'));

	}

	handlePrevClick() {

		document.dispatchEvent(new CustomEvent('MoveToPrevNFT'));

	}

	render() {

		return (
			<>
				<button id='next-nft-btn' className='nft-iterate-btns invisible' onClick={this.handleNextClick}>Next NFT</button>
				<button id='prev-nft-btn' className='nft-iterate-btns invisible' onClick={this.handlePrevClick}>Prev NFT</button>
			</>
		)

	}

}

export default UIButtons;
